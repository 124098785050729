// import React, { Suspense, lazy, useEffect } from 'react';
// import 'semantic-ui-css/semantic.min.css';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { ThemeProvider } from 'emotion-theming';
// import { ApolloProvider } from '@apollo/client';
// import { Global } from '@emotion/core';
// import { Loader } from 'semantic-ui-react';
// import { client } from './graphql/ApolloClient';
// import theme from './styles/theme';
// import CartStore from './services/CartStoreService';
// import ScrollToTop from './shared/ScrollToTop';
// import ErrorBoundary from './wrappers/ErrorBoundary';
// import withAnalytics from './wrappers/withAnalytics';
// import { globalStyles } from './styles/globalStyles';
// import { UPDATE_COOKIE_CONSENT } from './graphql/mutations';
// import Auth from './services/AuthService';

// const PrivateRoute = lazy(() => import('./components/routers/PrivateRoute'));
// const PublicRoute = lazy(() => import('./components/routers/PublicRoute'));
// const HostRoute = lazy(() => import('./components/routers/HostRoute'));
// const MemberRoute = lazy(() => import('./components/routers/MemberRoute'));
// const RequestInterface = lazy(() => import('./components/pages/RequestInterface'));
// const DashboardRouter = lazy(() => import('./components/routers/DashboardRouter'));
// const LandingPage = lazy(() => import('./components/pages/LandingPage'));
// const Cart = lazy(() => import('./components/pages/Cart'));
// const Login = lazy(() => import('./components/pages/Login'));
// const OrderDetails = lazy(() => import('./components/pages/OrderDetails'));
// const OrderOverview = lazy(() => import('./components/pages/OrderOverview'));
// const AccountDetails = lazy(() => import('./components/pages/AccountDetails'));
// const CheckoutRouter = lazy(() => import('./components/routers/CheckoutRouter'));
// const StorePage = lazy(() => import('./components/pages/StorePage'));
// const ProductsPage = lazy(() => import('./components/pages/ProductsPage'));
// const ProductDetails = lazy(() => import('./components/pages/ProductDetails'));
// const SubscriptionManagement = lazy(() => import('./components/pages/SubscriptionManagement'));
// const UpcomingOrderDetails = lazy(() => import('./components/pages/UpcomingOrderDetails'));
// const MembersDetails = lazy(() => import('./components/pages/MembersDetails'));
// const DeliverySettings = lazy(() => import('./components/pages/DeliverySettings'));
// const PaymentSettings = lazy(() => import('./components/pages/PaymentSettings'));
// const UserContactSettings = lazy(() => import('./components/pages/UserContactSettings'));
// const SubscriptionEdit = lazy(() => import('./components/pages/SubscriptionEdit'));
// const SubscriptionCancel = lazy(() => import('./components/pages/SubscriptionCancel'));
// const SubscriptionPause = lazy(() => import('./components/pages/SubscriptionPause'));
// const InviteFriends = lazy(() => import('./components/pages/InviteFriends'));
// const SubscribeFeldbotschaftNotificationSettings = lazy(() =>
//   import('./components/pages/SubscribeFeldbotschaftNotificationSettings')
// );
// const NotFound = lazy(() => import('./components/pages/NotFound'));

const App = () => {
  // Redirect to main website
  window.location.href = 'https://querfeld.bio/blog/warum-wir-aufhoren-mussten';
  // useEffect(() => {
  //   // hydrate Apollo cache with stored cart
  //   CartStore.hydrateCache();

  //   // Listener to update cookie settings if user logged in
  //   ['CookieScriptAccept', 'CookieScriptAcceptAll', 'CookieScriptReject'].forEach((e) => {
  //     window.addEventListener(e, () => {
  //       if (!window.rudderLoaded) return;
  //       if (Auth.isUserAuthenticated() || Auth.isPreAuthenticated()) {
  //         client.mutate({
  //           mutation: UPDATE_COOKIE_CONSENT,
  //         });
  //       }
  //     });
  //   });

  //   return () => {
  //     // when user leaves/refreshes the page
  //     CartStore.saveCacheToCartStore();
  //   };
  // });

  // return (
  //   <ApolloProvider client={client}>
  //     <Global styles={globalStyles} />
  //     <ThemeProvider theme={theme}>
  //       <Router>
  //         <ErrorBoundary>
  //           <Suspense fallback={<Loader active />}>
  //             <ScrollToTop />
  //             <Switch>
  //               <Route exact path="/login" component={Login} />
  //               <PublicRoute
  //                 exact
  //                 path="/cart"
  //                 withContainer={false}
  //                 component={withAnalytics(Cart)}
  //                 title="Warenkorb - Gemüseabo bestellen | Querfeld"
  //               />
  //               <PublicRoute
  //                 exact
  //                 path="/register"
  //                 withContainer={false}
  //                 component={withAnalytics(CheckoutRouter)}
  //                 title="Abo einrichten - Querfeld Konto | Querfeld"
  //                 index
  //               />
  //               <PublicRoute
  //                 exact
  //                 path="/delivery"
  //                 withContainer={false}
  //                 component={withAnalytics(CheckoutRouter)}
  //                 title="Abo einrichten - Liefermethode | Querfeld"
  //               />
  //               <PublicRoute
  //                 exact
  //                 path="/checkout"
  //                 component={withAnalytics(CheckoutRouter)}
  //                 title="Abo einrichten - Zahlung | Querfeld"
  //                 withContainer={false}
  //               />
  //               <PublicRoute
  //                 exact
  //                 path="/success"
  //                 component={withAnalytics(CheckoutRouter)}
  //                 title="Abo abgeschlossen - Willkommen | Querfeld"
  //                 withContainer={false}
  //               />
  //               <PublicRoute
  //                 exact
  //                 path="/landing"
  //                 component={withAnalytics(LandingPage)}
  //                 title="Bio Gemüsekiste online kaufen in nachhaltig & fair | Querfeld"
  //                 withContainer={false}
  //                 index
  //               />
  //               <PublicRoute
  //                 exact
  //                 path="/products"
  //                 component={withAnalytics(ProductsPage)}
  //                 title="Bio Gemüsekiste online kaufen in nachhaltig & fair | Querfeld"
  //                 withContainer={false}
  //                 index
  //               />
  //               <PublicRoute
  //                 exact
  //                 withContainer={false}
  //                 path="/upcoming-order/:feldbotschaftId"
  //                 component={withAnalytics(OrderOverview)}
  //                 title="Querfeld: Order Overview"
  //               />
  //               <PrivateRoute
  //                 exact
  //                 withContainer={false}
  //                 path="/manage"
  //                 component={withAnalytics(DashboardRouter)}
  //               />
  //               <PrivateRoute
  //                 exact
  //                 path="/manage/request"
  //                 component={withAnalytics(RequestInterface)}
  //               />
  //               <HostRoute
  //                 exact
  //                 path="/upcoming-order"
  //                 component={withAnalytics(UpcomingOrderDetails)}
  //               />
  //               <HostRoute exact path="/members" component={withAnalytics(MembersDetails)} />
  //               <HostRoute
  //                 exact
  //                 path="/order/:orderId"
  //                 withContainer={false}
  //                 component={withAnalytics(OrderDetails)}
  //               />
  //               <PrivateRoute
  //                 exact
  //                 path="/account"
  //                 withContainer={false}
  //                 component={withAnalytics(AccountDetails)}
  //               />
  //               <PrivateRoute
  //                 exact
  //                 path="/payment-settings"
  //                 withContainer={false}
  //                 component={withAnalytics(PaymentSettings)}
  //               />
  //               <MemberRoute
  //                 exact
  //                 path="/delivery-settings"
  //                 withContainer={false}
  //                 component={withAnalytics(DeliverySettings)}
  //               />
  //               <MemberRoute
  //                 exact
  //                 path="/contact-settings"
  //                 withContainer={false}
  //                 component={withAnalytics(UserContactSettings)}
  //               />
  //               <MemberRoute
  //                 exact
  //                 path="/feldbotschaft-notifications-settings"
  //                 withContainer={false}
  //                 component={withAnalytics(SubscribeFeldbotschaftNotificationSettings)}
  //               />
  //               <PrivateRoute
  //                 exact
  //                 path="/subscriptions"
  //                 withContainer={false}
  //                 component={withAnalytics(SubscriptionManagement)}
  //               />
  //               <PrivateRoute
  //                 exact
  //                 path="/subscription/:subscriptionId"
  //                 withContainer={false}
  //                 component={withAnalytics(SubscriptionEdit)}
  //               />
  //               <PrivateRoute
  //                 exact
  //                 path="/subscription/:subscriptionId/cancel"
  //                 withContainer={false}
  //                 component={withAnalytics(SubscriptionCancel)}
  //               />
  //               <PrivateRoute
  //                 exact
  //                 path="/subscription/:subscriptionId/pause"
  //                 withContainer={false}
  //                 component={withAnalytics(SubscriptionPause)}
  //               />
  //               <PrivateRoute
  //                 exact
  //                 path="/subscription/:subscriptionId/pause-with-donation"
  //                 withContainer={false}
  //                 component={withAnalytics(SubscriptionPause)}
  //               />
  //               <MemberRoute
  //                 exact
  //                 path="/invite"
  //                 withContainer={false}
  //                 component={withAnalytics(InviteFriends)}
  //               />
  //               <PublicRoute
  //                 exact
  //                 path="/"
  //                 title="Gemüseabo online bestellen - Lieferung & Abholstation | Querfeld"
  //                 component={withAnalytics(StorePage)}
  //                 withContainer={false}
  //                 index
  //               />
  //               <PublicRoute
  //                 exact
  //                 path="/product/:articleId"
  //                 component={withAnalytics(ProductDetails)}
  //                 withContainer={false}
  //               />
  //               <PublicRoute
  //                 title="Querfeld: 404"
  //                 component={withAnalytics(NotFound)}
  //                 withContainer
  //               />
  //             </Switch>
  //           </Suspense>
  //         </ErrorBoundary>
  //       </Router>
  //     </ThemeProvider>
  //   </ApolloProvider>
  // );
};

export default App;
